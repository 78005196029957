
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Watch } from "vue-property-decorator";
import * as toastr from "toastr";
import ApiButton from "@/components/ApiButton.vue";
import SvySection from "@/components/Survey/Section.vue";
import StatusSetter from "@/components/Survey/StatusSetter.vue";

import apiClient from "@/stuff/ApiClient";
import utils from "@/stuff/Utils";
import { SurveyUtils, ISupplierResponseMap } from "@/stuff/SurveyUtils";
import dirtyness from "@/stuff/Dirtyness";
import eventBus from "@/stuff/EventBus";
import { Survey } from "@/model/Survey";
import { SurveyPage } from "@/model/SurveyPage";
import { SurveySection } from "@/model/SurveySection";
import { ISupplierResponses, SupplierResponses } from "@/model/SupplierResponses"; 
import { SupplierResponseSet } from "@/model/SupplierResponseSet"; 
import { SurveyQuestionOptions } from "@/model/SurveyQuestionOptions";
import { LookupGroup, ResponseSetStatus, ResponseStatus } from "@/model/Enums";
import { IStatusChangedArguments } from "@/model/StatusChangedArguments";
import { IModule, Module } from "@/model/Module";
import fileDownload from "@/stuff/FileDownload";
import { ILookupItem, LookupItem } from "@/model/LookupItem";

@Component({
    components: { ApiButton, SvySection, StatusSetter }
})
export default class Page extends Vue {

    async mounted() {
        this.isSupplierZone = this.$router.currentRoute.path.toLowerCase().indexOf("supplierzone") > -1;
        await this.getLookups();
        await this.loadSurvey();
        await this.loadModule();
        await this.fetchCanCopy();
    }

    //
    // -- properties
    //

    @Prop({ required: true })
    responseSet!: SupplierResponseSet;
        
    @Prop({ default: SurveyQuestionOptions.CreateDefault })
    surveyQuestionOptions!: SurveyQuestionOptions;

    module: Module = new Module();

    isWaiting: boolean = true;
    survey: Survey = new Survey();
    pageIndex = 0;
    isDirty: boolean = false; // we need a reactive property on this component for dirtyness
    isSupplierZone: boolean = false;
    
    rejectedReasons: Array<ILookupItem> = [];
    prefillReasonID: number = 0;
    rejectReason: string = "";

    canCopyResponses: boolean = false;

    get page(): SurveyPage {
        if(!this.survey) return new SurveyPage();
        const page = this.visiblePages[this.pageIndex];
        return page || new SurveyPage();
    }

    get visiblePages(): Array<SurveyPage> {
        return this.survey.surveyPages.filter(p => !p.shouldHideBasedOnResponse);
    }

    get visibleSections(): Array<SurveySection> {
        return this.page.surveySections.filter(s => !s.shouldHideBasedOnResponse);
    }

    get hasPrevious(): boolean {
        if(!this.survey || !this.visiblePages) return false;
        return this.pageIndex > 0;
    }

    get hasNext(): boolean {
        if(!this.survey || !this.visiblePages) return false;
        return this.pageIndex < (this.visiblePages.length - 1);
    }

    get shouldShowSave(): boolean {
        // show save if not admin (supplier) & awaiting completion/rejected
        // if admin, only show if is submitted
        return  (
                    !this.surveyQuestionOptions.isAdmin && 
                    (
                        this.surveyQuestionOptions.responseSetStatus == ResponseSetStatus.AwaitingCompletion ||
                        this.surveyQuestionOptions.responseSetStatus == ResponseSetStatus.Rejected
                    )
                ) ||
                (   this.surveyQuestionOptions.isAdmin && 
                    this.surveyQuestionOptions.responseSetStatus === ResponseSetStatus.Submitted
                );
    }

    get shouldShowUserGuide(): boolean {
        return !utils.isEmptyId(this.module.guideDocumentID)
    }

    get shouldShowModuleDocs(): boolean {
        return this.module.documentIDs.length > 0;
    }

    get shouldShowPrint(): boolean {
        return this.surveyQuestionOptions.responseSetStatus == ResponseSetStatus.Accepted
    }

    // store of responses in survey
    // - used to send back to server 
    responseMap: ISupplierResponseMap = {};

    // nb - see also stuff in supplier.vue (isDirty flag, event bus handler, beforeRouteLeave, ... )
    readonly dirtynessOptions = { 
        model: this.responseMap,
        isDeep: true,
        excludeList: []
    };

    //
    // -- watchers
    //

    @Watch("responseSet")
    async onSupplierResponseSetChanged() {
        console.log("**** onSupplierResponseSetChanged: survey = ", this.responseSet.surveyID);
        await this.loadSurvey();
        await this.loadModule();
        await this.fetchCanCopy();
    }

    @Watch("responseMap", { deep: true })
    onResponseChanged() {
        console.log("### Page - onResponseChanged()");
    }

    @Watch("survey", { deep: true })
    onSurveyChanged() {

        console.log("onSurveyChanged - responseMap = ", this.responseMap);

        dirtyness.dataChanged(this.dirtynessOptions);
        this.isDirty = dirtyness.isDirty;
        SurveyUtils.updateQuestionDependencies(this.survey, this.responseMap);
        console.log("### Page - onSurveyChanged() - this.isDirty = ", this.isDirty);
    } 

    @Watch("responseSet.moduleID")
    onModuleChanged() {
        this.loadModule();
    }

    //
    // -- events
    //

    async onStatusChanged(args: IStatusChangedArguments) {
        console.log(`#### Page - onStatusChanged - args:${args}`);

        // save any changes
        await this.saveResponses();

        if (args.status == ResponseSetStatus.Submitted) {
            await this.$bvModal.show("afterSubmitDialogue");
        }

        // just bubble it up...
        this.$emit("status-changed", args);
    }

    // Either this ugly thing or duplicate save responses in StatusSetter...
    async onShowingAcceptDialogue() {
        await this.saveResponses();
        const setter = this.$refs.statusSetterComponent as StatusSetter;
        setter.hasSavedResponsesPriorToAccept();
    }

    //
    // -- methods
    //

    async getLookups () {
        this.rejectedReasons.length = 0;
        const response: Array<ILookupItem> = await apiClient.get(`/api/lookup/search/${LookupGroup.RejectedReasons}`);
        this.rejectedReasons.push(...response.map((r: ILookupItem) => new LookupItem(r)));
    }

    async loadSurvey() {
        if(!this.responseSet || this.responseSet.surveyID === 0 || this.responseSet.id === 0) {
            console.warn("Page.vue - loadSurvey - supplierResponseSet not initialised");
            return;
        }

        console.log("### Page - loadSurvey() - url = ", `api/survey/load?surveyID=${this.responseSet.surveyID}&supplierResponseSetID=${this.responseSet.id}`);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const loader = (this as any).$loading.show({
            container:this.$refs.pageContainer,
            canCancel: false
        });
        this.isWaiting = true;
        this.pageIndex = 0;

        const surveyData = await apiClient.get(`api/survey/load?surveyID=${this.responseSet.surveyID}&supplierResponseSetID=${this.responseSet.id}`);

        // assemble data 'offline' before assigning to the component and making it reactive
        const survey = new Survey();       
        survey.update(surveyData.survey);

        // tortured wrangling of survey and supplier response data...(such a mess)
        const responseMap = SurveyUtils.dataToResponseMap(surveyData.responses);
        SurveyUtils.addResponsesToSurvey(this.responseSet.id, survey, responseMap); // this adds empty responses too
        SurveyUtils.surveyToResponseMap(survey, responseMap); // update the map
        SurveyUtils.updateQuestionDependencies(survey, responseMap); // show / hide questions

        // now assign to component...
        this.survey = survey;
        this.responseMap = {...responseMap};
        this.$nextTick(() => {
            this.dirtynessOptions.model = this.responseMap;
            dirtyness.setClean(this.dirtynessOptions);
        });

        loader.hide();
        this.isWaiting = false;
    }

    private async loadModule() {
        if (utils.isEmptyId(this.responseSet.moduleID)) return;

        const response: IModule = await apiClient.get(`api/module/load?id=${this.responseSet.moduleID}`)
        this.module.update(response);
    }

    private async fetchCanCopy() {
        if (utils.isEmptyId(this.responseSet.id)) return;
        
        const response: boolean = await apiClient.get(`api/supplierResponse/canCopyResponseSet?id=${this.responseSet.id}`)
        this.canCopyResponses = response;

        if (this.canCopyResponses) this.showCopyDialog();
    }
    
    async saveResponses(showWarning: boolean = false) {

        // validation - use event bus to trigger validation on all the child components that need to validate
        eventBus.$emit("touch-to-validate");

        // subsequent logic needs to know whether the question got hidden or not...
        // (see SurveyUtils.updateQuestionDependencies for hide logic)
        SurveyUtils.setHiddenAndExemptStatus(this.survey, this.responseMap);

        const responseArray = Object.values(this.responseMap) as Array<SupplierResponses>;

        if(this.surveyQuestionOptions.isAdmin) {
            for(const response of responseArray) {
                if(response.status === ResponseStatus.Rejected && utils.isEmptyOrWhitespace(response.rejectReason)) {
                    toastr.info("A reject reason is missing", "Cannot save");
                    return;
                }
            }
        }

        if (showWarning && this.isSupplierZone) {
            await this.$bvModal.msgBoxOk(
                "Please note, this module will not be submitted & reviewed by CQMS Safety-Scheme until you click 'Submit' and complete the declaration.");
        }

        this.isWaiting = true;
        const updatedResponses: Array<ISupplierResponses> = await apiClient.post("api/SupplierResponse/saveResponses?surveyID=" + this.responseSet.surveyID.toString() + "&supplierResponseSetID=" + this.responseSet.id.toString(), responseArray);
        for(const responseData of updatedResponses) {
            const itm = this.responseMap[responseData.surveyQuestionID];
            if(itm) itm.update(responseData);
        }

        this.fetchCanCopy();

        dirtyness.setClean(this.dirtynessOptions);
        this.isWaiting = false;
    }

    goToPrevious() {
        // TODO - should do validation here?
        if(!this.hasPrevious) return;
        this.pageIndex--;
    }

    goToNext() {
        // TODO - should do validation here?
        if(!this.hasNext) return;
        this.pageIndex++;
    }

    downloadUserGuide() {
        fileDownload.download(this.module.guideDocument);
    }

    downloadModuleDocs() {
        this.module.documents.forEach(doc => {
            fileDownload.download(doc);
        });
    }

    async printReport() {
        const blob: Blob = await apiClient.get("api/report/survey?supplierResponseSetID=" + this.responseSet.id);
        utils.downloadBlob(document, blob, `survey-${utils.timeStampText()}.pdf`);
    }

    showReject() {
        // validation - use event bus to trigger validation on all the child components that need to validate
        eventBus.$emit("touch-to-validate");

        // subsequent logic needs to know whether the question got hidden or not...
        // (see SurveyUtils.updateQuestionDependencies for hide logic)
        SurveyUtils.setHiddenAndExemptStatus(this.survey, this.responseMap);

        const responseArray = Object.values(this.responseMap) as Array<SupplierResponses>;

        if(this.surveyQuestionOptions.isAdmin) {
            for(const response of responseArray) {
                if(response.status === ResponseStatus.Rejected && utils.isEmptyOrWhitespace(response.rejectReason)) {
                    toastr.info("A reject reason is missing", "Cannot save");
                    return;
                }
            }
        }
        
        this.rejectReason = "";
        this.$bvModal.show("rejectDialogue");
    }

    cancelReject() {
        this.rejectReason = "";
        this.$bvModal.hide("rejectDialogue");
    }

    showCopyDialog() {
        this.$bvModal.show("copyResponseSetDialogue")
    }

    cancelCopy() {
        this.$bvModal.hide("copyResponseSetDialogue")
    }

    async copyResponses() {
        const response: { message: string } = await apiClient.get(`/api/supplierResponse/copyResponseSet?id=${this.responseSet.id}`)
        if (response.message == "ok") {
            this.$bvModal.hide("copyResponseSetDialogue")
            this.onSupplierResponseSetChanged();
        }
    }

    async saveReject() {
        if(utils.isEmptyOrWhitespace(this.rejectReason)) {
            toastr.warning("Please enter a reason");
            return;
        }
        // new status needs to bubble up and trickle down - can't change directly
        const args: IStatusChangedArguments = {
            status: ResponseSetStatus.Rejected,
            rejectReason: this.rejectReason,
            activeFrom: null,
            expires: null,
            acceptReason: null,
            declaration: null
        };

        await this.onStatusChanged(args);

        this.$bvModal.hide("rejectDialogue");
    }
}
