
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

import Checkboxes from "@/components/Survey/Checkboxes.vue";
import RadioButtons from "@/components/Survey/RadioButtons.vue";
import FreeText from "@/components/Survey/FreeText.vue";
import SingleLineText from "@/components/Survey/SingleLineText.vue";
import DatePicker from "@/components/Survey/DatePicker.vue";
import Money from "@/components/Survey/Money.vue";
import Integer from "@/components/Survey/Integer.vue";
import Evidence from "@/components/Survey/Evidence.vue";
import Matrix from "@/components/Survey/Matrix.vue";
import NoResponse from "@/components/Survey/NoResponse.vue";
import utils from "@/stuff/Utils";
import { SurveySection } from "@/model/SurveySection";
import { SurveyQuestion } from "@/model/SurveyQuestion";
import { SurveySectionType } from "@/model/Enums";
import { SurveyQuestionOptions } from "@/model/SurveyQuestionOptions";

@Component({
    components: { Checkboxes, RadioButtons, FreeText, SingleLineText, DatePicker, Money, Integer, Evidence, Matrix, NoResponse }
})
export default class Part extends Vue {

    @Prop({ required: true })
    private section!: SurveySection;

    @Prop({ default: SurveyQuestionOptions.CreateDefault })
    private surveyQuestionOptions!: SurveyQuestionOptions;
 
    private get isTwoColumn(): boolean {
        return this.section.type === SurveySectionType.TwoColumn;
    }

    private get isExtra(): boolean {
        return !utils.isEmptyOrWhitespace(this.section.extra);
    }

    private get visibleQuestions(): Array<SurveyQuestion> {
        return this.section.surveyQuestions.filter(q => !q.shouldHideBasedOnResponse);
    }

    private get columnOneQuestions(): Array<SurveyQuestion> {
        if(!this.isTwoColumn) return [];
        return this.visibleQuestions.filter((q, i) => i % 2 !== 0);
    }

    private get columnTwoQuestions(): Array<SurveyQuestion> {
        if(!this.isTwoColumn) return [];
        return this.visibleQuestions.filter((q, i) => i % 2 === 0);
    }
}
