import { Survey } from "@/model/Survey";   
import { SurveyPage } from "@/model/SurveyPage";
import { SurveySection } from "@/model/SurveySection";
import { SurveyQuestion } from "@/model/SurveyQuestion";   
import { ISupplierResponses, SupplierResponses } from "@/model/SupplierResponses"; 
import { ResponseStatus } from "@/model/Enums";

// key is question ID
export interface ISupplierResponseMap {
    [key: number]: SupplierResponses;
} 

export module SurveyUtils {

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function walkSurveyTree(node: any, callForEachNode: (item: any) => void) {
        if(node == null) return;

        callForEachNode(node);
        
        // look further down the tree...
        for (const key in node) {
            if (node.hasOwnProperty(key)) {
                const child = node[key];
                if (Array.isArray(child)) {
                    for (let i = 0; i < child.length; i++) {
                        walkSurveyTree(child[i], callForEachNode);
                    }
                }
                else if (typeof child === "object") {
                    walkSurveyTree(child, callForEachNode);
                }
            }       
        }
    }

    // converts to a 'dictionary' (in C# speak) of responses keyed on question ID
    export function dataToResponseMap(responseData: Array<ISupplierResponses>): ISupplierResponseMap {
        const map: ISupplierResponseMap = {};
        for (const response of responseData){
            map[response.surveyQuestionID] = new SupplierResponses(response);
        }
        return map;
    }
    
    // match up responses to the question objects
    export function addResponsesToSurvey(supplierResponseSetID: number, survey: Survey, responses: ISupplierResponseMap) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        walkSurveyTree(survey, (node: any) => {
            if(SurveyQuestion.isASurveyQuestion(node)) {
                const question: SurveyQuestion = node;
                const response = responses[question.id];
                if(typeof response !== "undefined") {
                    // the business:
                    question.supplierResponses = response;
                }
                else {
                    // make sure empty spaces have 'empty' responses
                    question.supplierResponses = new SupplierResponses();
                    question.supplierResponses.surveyQuestionID = question.id;
                    question.supplierResponses.supplierResponseSetID = supplierResponseSetID;    
                }
            }
        });
    }

     // update the map (dictionary) of responses from survey tree
     // NB - we already called dataToResponseMap() to get the map but 
     //      this will add empty responses added by addResponsesToSurvey()
     export function surveyToResponseMap(survey: Survey, responses: ISupplierResponseMap)  {
        // clear down first
        for (const key in responses) if (responses.hasOwnProperty(key)) delete responses[key];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        walkSurveyTree(survey, (node: any) => {
            if(SupplierResponses.isASupplierResponse(node)) {
                const response: SupplierResponses = node;
                responses[response.surveyQuestionID] = response;
            }
        });
    }

    function isAShouldHideFunction(functionText: string): boolean {
        if(!functionText) return false;
        if(!functionText.startsWith("return")) return false;
        if(!functionText.endsWith(";")) return false;
        return true;
    }

    // this will get called EVERY time there's a change in the survey...
    export function updateQuestionDependencies(survey: Survey, responses: ISupplierResponseMap) {
        // build a list of response IDs
        const selectedResponseIDs: Array<number> = [];
        for (const questionID in responses) {
            if (responses.hasOwnProperty(questionID)) {
                const response = responses[questionID];
                for (let i = 0; i < response.responseItems.length; i++) {
                    const responseItem = response.responseItems[i];
                    if(responseItem.isSelected) {
                        selectedResponseIDs.push(responseItem.responseID);
                    }
                }
            }
        }

//const testFunc = "return page.id === 2 && responses[59] !== undefined && responses[59].responseIDs.length > 0;";
//const testFunc = "return page.id === 2 && responses[14] !== undefined;";

console.log("updateQuestionDependencies responses =", responses);
//console.log("responses[14] =", responses[14]);


//const questionTestFunc = "return selectedResponseIDs.indexOf(10) === -1;";

        // show / hide
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        walkSurveyTree(survey, (item: any) => {

            // "new Function" is potentially a big security no-no but we trust the script it's running
            //    - Note that we can hide based on this condition at THREE levels.
            //    - IF a question is hidden, we should flag it so that ResponseStatus can be appropriately set on the sever in business-logic.

            if(SurveyPage.isASurveyPage(item)) {
                const page = item as SurveyPage;
                if (isAShouldHideFunction(page.hideCondition)) {
                    page.shouldHideBasedOnResponse = new Function("page", "responses", "selectedResponseIDs", page.hideCondition)(page, responses, selectedResponseIDs);    
                }
                else {
                    page.shouldHideBasedOnResponse = false;
                }
            }

            if(SurveySection.isASurveySection(item)) {
                const section = item as SurveySection;
                if (isAShouldHideFunction(section.hideCondition)) {
                    section.shouldHideBasedOnResponse = new Function("section", "responses", "selectedResponseIDs", section.hideCondition)(section, responses, selectedResponseIDs);    
                }
                else {
                    section.shouldHideBasedOnResponse = false;
                }
            }

            if(SurveyQuestion.isASurveyQuestion(item)) {
                const question = item as SurveyQuestion;

                if (isAShouldHideFunction(question.hideCondition)) {
                    question.shouldHideBasedOnResponse = new Function("question", "responses", "selectedResponseIDs", question.hideCondition)(question, responses, selectedResponseIDs);    
                }
                else {
                    question.shouldHideBasedOnResponse = false;
                }
            }
        });
    }

    // Gets called just before saving responses
    export function setHiddenAndExemptStatus(survey: Survey, responses: ISupplierResponseMap) {
        let page: SurveyPage | null = null;
        let section: SurveySection | null = null;
        // I suppose this could just as easily have been a set of nested for loops...
        walkSurveyTree(survey, (item: any) => {
            if(SurveyPage.isASurveyPage(item)) page = item as SurveyPage;
            if(SurveySection.isASurveySection(item)) section = item as SurveySection;
            if(SurveyQuestion.isASurveyQuestion(item)) {
                // page and section shouldn't actually be null...
                const pageIsHidden = page != null ? page.shouldHideBasedOnResponse : false;
                const sectionIsHidden = section != null ? section.shouldHideBasedOnResponse : false;
                const question = item as SurveyQuestion;
                // if question or any parent is hidden, set the status so we can count things properly on the server
                const isQuestionHidden = pageIsHidden || sectionIsHidden || question.shouldHideBasedOnResponse;
                
                const response = responses[question.id];
// const pageID = page != null ? page.id : 0;
// const sectionID = section != null ? section.id : 0;
// console.log(`setHiddenStatus | page=${pageID} | section=${sectionID} | question=${question.id} | ${question.description} | hidden=${isQuestionHidden}`);
                if(response != null) {
                    if (isQuestionHidden) {
                        response.status = ResponseStatus.Hidden;
                    }
                    else if(question.isExemptFromApproval) {
                        response.status = ResponseStatus.ExemptFromApproval;
                    }
                    else if (response.status === ResponseStatus.Hidden) {
                        // need to unhide (server will set status to Entered if appropriate)
                        response.status = ResponseStatus.None;
                    }
                }
            }
        });
    }
}