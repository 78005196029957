
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Validations } from "vuelidate-property-decorators";
import DatePicker from "@/components/DatePicker.vue";
import * as toastr from "toastr";
import ApiButton from "@/components/ApiButton.vue";
import apiClient from "@/stuff/ApiClient";
import utils from "@/stuff/Utils";
import { ISupplierResponseSet, SupplierResponseSet } from "@/model/SupplierResponseSet";
import { SurveyQuestionOptions } from "@/model/SurveyQuestionOptions";
import { IStatusChangedArguments } from "@/model/StatusChangedArguments";
import { ResponseSetStatus, TriState } from "@/model/Enums";
import { ILookupItem } from "@/model/LookupItem";
import { IModule, Module } from "@/model/Module";
import { ISupplier, Supplier } from "@/model/Supplier";
import { ContactSearchParameters } from "@/model/ContactSearchParameters";
import { Contact, IContact } from "@/model/Contact";
import store from "@/store/store";
import { IUser, User } from "@/model/User";
import { Authentication } from "@/stuff/Authentication";

@Component({ components: { ApiButton, DatePicker }})
export default class StatusSetter extends Vue {

    // properties

    @Prop({ required: true })
    responseSet!: SupplierResponseSet;

    @Prop({ default: SurveyQuestionOptions.CreateDefault })
    surveyQuestionOptions!: SurveyQuestionOptions;

    @Prop({ default: false })
    areResponsesDirty!: boolean;

    declarationName: string = "";
    declarationJobRole: string = "";
    detailsCorrect: boolean|null = null;

    activeFromDate: Date|null = this.responseSet.activeFrom;
    expiresDate: Date|null = this.responseSet.expires;
    acceptReason: string = "";

    declaration: string = "";
    module: Module = new Module();
    supplier: Supplier = new Supplier();
    contacts: Array<Contact> = [];

    user: User = new User();

    get shouldShowSubmit(): boolean {
        return !this.surveyQuestionOptions.isAdmin && 
                this.surveyQuestionOptions.responseSetStatus !== ResponseSetStatus.Submitted &&
                this.surveyQuestionOptions.responseSetStatus !== ResponseSetStatus.Accepted;
    }

    get tradingAreaList(): Array<ILookupItem> { return this.$store.state.tradingAreaList; }

    // methods

    async mounted(): Promise<void> {
        this.user.update((Authentication.signedInUser() as IUser));
    }

    async showSubmit(): Promise<void> {
        await store.dispatch("loadTradingAreaList");

        await Promise.all([
            this.fetchDeclaration(),
            this.fetchModule(),
            this.fetchSupplier(),
            this.fetchContacts()
        ]);

        this.declarationName = "";
        this.declarationJobRole = "";
        this.detailsCorrect = null;

        this.$bvModal.show("submitDialogue");
    }

    private async fetchDeclaration() {
        this.declaration = await apiClient.get(`api/module/declaration?id=${this.responseSet.moduleID}`);
    }

    private async fetchModule() {
        const response: IModule = await apiClient.get(`api/module/load?id=${this.responseSet.moduleID}`);
        this.module.update(response);
    }

    private async fetchSupplier() {
        const response: ISupplier = await apiClient.get(`api/supplier/load?id=${this.responseSet.supplierID}`);
        this.supplier.update(response);
    }

    private async fetchContacts() {
        const parameters = new ContactSearchParameters(); // TODO - make parameters a property?
        parameters.supplierID = this.responseSet.supplierID;
        parameters.isDeleted = TriState.False;

        const responseData = await apiClient.post("api/contact/search", parameters);
        this.contacts = responseData.list.map((c: IContact) => new Contact(c));
    }

    cancelSubmit(): void {
        this.$bvModal.hide("submitDialogue");
    }

    async saveSubmit(): Promise<void> {
        // 'touch' all the fields to activate the validation messages
        this.$v.$touch();
        if (this.$v.declarationName.$invalid || this.$v.declarationJobRole.$invalid) {
            toastr.info("Please fix the highlighted errors", "Validation errors");
            return;
        }

        if (this.module.confirmOnSubmission && this.detailsCorrect == null) {
            toastr.info("Please indicate if details are correct", "Validation errors");
            return;
        }

        // new status needs to bubble up and trickle down - can't change directly
        const args: IStatusChangedArguments = {
            status: ResponseSetStatus.Submitted,
            rejectReason: null,
            activeFrom: null,
            expires: null,
            acceptReason: null,
            declaration: {
                name: this.declarationName,
                jobRole: this.declarationJobRole,
                detailsCorrect: this.detailsCorrect
            }
        };
        this.$emit("status-changed", args);

        this.$bvModal.hide("submitDialogue");
    }

    async termsAndConditions(): Promise<void> {
        const url = "api/terms/download";
        const blob: Blob = await apiClient.getNonJson(url);
        utils.downloadBlob(document, blob, "T&Cs")
    }


    //
    // -- accept
    //

    get shouldShowAccept(): boolean {
        return this.surveyQuestionOptions.isAdmin && 
                this.surveyQuestionOptions.responseSetStatus === ResponseSetStatus.Submitted;
    }

    get canAccept(): boolean {
        return this.responseSet.questionCountNone === 0 && 
                this.responseSet.questionCountEntered === 0 && 
                this.responseSet.questionCountRejected === 0;
    }

    showAccept(): void {
        // we want to save the responses first to update statuses if necessary
        // (would have been messy to duplicate server logic here)
        this.$emit("showing-accept-dialogue");

        this.activeFromDate = this.responseSet.activeFrom;
        this.expiresDate = this.responseSet.expires;
        this.acceptReason = "";
        this.$bvModal.show("acceptDialogue");
    }

    async hasSavedResponsesPriorToAccept(): Promise<void> {
        // update the counts
        const data: ISupplierResponseSet = await apiClient.get(`/api/supplierResponse/load?id=${this.responseSet.id}`);
        this.responseSet.update(data);
    }

    cancelAccept(): void {
        this.$bvModal.hide("acceptDialogue");
    }

    saveAccept(): void {
        // 'touch' all the fields to activate the validation messages
        this.$v.$touch();
        if (this.$v.activeFromDate.$invalid || this.$v.expiresDate.$invalid) {
            toastr.info("Please fix the highlighted errors", "Validation errors");
            return;
        }

        // new status needs to bubble up and trickle back down - can't change directly
        const args: IStatusChangedArguments = {
            status: ResponseSetStatus.Accepted,
            rejectReason: null,
            activeFrom: this.activeFromDate,
            expires: this.expiresDate,
            acceptReason: this.acceptReason,
            declaration: null
        };
        this.$emit("status-changed", args);
        this.$bvModal.hide("acceptDialogue");
    }

    get shouldShowReject(): boolean {
        return this.surveyQuestionOptions.isAdmin && 
                this.surveyQuestionOptions.responseSetStatus === ResponseSetStatus.Submitted;
    }

    reject(): void {
        this.$emit("reject")
    }

    //
    // -- validation
    //

    areDatesInRightOrder(): boolean {
        if(this.responseSet == null) return true;
        if(!utils.hasDateValue(this.expiresDate)) return true;
        if(!utils.hasDateValue(this.activeFromDate)) return true;
        return utils.areDatesAscending(this.activeFromDate, this.expiresDate);
    }

    @Validations()
    validations():unknown {
        const validations: any = {};
        validations.activeFromDate = { 
            hasDate: (value: Date) => utils.hasDateValue(value),
            isInRightOrder: () => this.areDatesInRightOrder()
        };
        validations.expiresDate = { 
            hasDate: (value: Date) => utils.hasDateValue(value),
            isInRightOrder: () => this.areDatesInRightOrder() 
        };

        validations.declarationName = { hasValue: (value: string) => !utils.isEmptyOrWhitespace(value) };
        validations.declarationJobRole = { hasValue: (value: string) => !utils.isEmptyOrWhitespace(value) };

        return validations;
    }
}
